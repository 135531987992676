<nav class="navbar-flex">
  <div class="logo">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/logo/logo_1.png" alt="Logo" />
    </a>
  </div>

  <div>
    <ng-container
      *ngIf="loggedInUserInfo$ | async as userInfo; else notLoggedIn"
    >
      <div *ngIf="userInfo.is_logged_in; else notLoggedIn">
        <div class="company-name">会員ID: {{ userInfo.member_id }}</div>
        <!-- <div>会員名: {{ userInfo.member_name }}</div> -->
        <div>
          <a (click)="onLogout()" class="navbar-link">ログアウト</a>
          <!-- <a routerLink="/mypage/dashboard/top" class="navbar-link"
            >マイページ</a
          > -->
        </div>
      </div>
    </ng-container>
    <ng-template #notLoggedIn>
      <div class="navbar-links-wrapper">
        <a routerLink="/auth/login" class="navbar-link">ログイン</a>
        <a routerLink="/auth/register" class="navbar-link">ユーザ登録</a>
      </div>
    </ng-template>
  </div>
</nav>
