import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CsrfTokenService } from '../services/csrf-token.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private csrfTokenService: CsrfTokenService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // POSTリクエストの場合のみCSRFトークンを追加
    if (request.method !== 'POST') {
      return next.handle(request);
    }

    // 開発環境の場合のみログを出力
    if (environment.enableVerboseLogging) {
      console.log('CsrfInterceptor: Setting CsrfToken for', request.url);
    }

    return this.csrfTokenService.getCsrfToken().pipe(
      switchMap((token) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              'X-CSRF-TOKEN': token,
            },
          });
        }
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        // CSRFトークンが無効な場合、新しいトークンを取得して再試行
        if (error.status === 403) {
          return this.csrfTokenService.refreshCsrfToken().pipe(
            switchMap((newToken) => {
              const newRequest = request.clone({
                setHeaders: {
                  'X-CSRF-TOKEN': newToken,
                },
              });
              return next.handle(newRequest);
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
