import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

/*
interface CsrfResponse {
  csrf_token: string;
}
  */

@Injectable({
  providedIn: 'root',
})
export class CsrfTokenService {
  private csrfToken: string | null = null;
  constructor(private http: HttpClient) {}

  /*
  getCsrfToken(): Observable<string> {
    return this.http
      .get<CsrfResponse>(`${environment.apiRoot}/auth/csrf-protection.php`)
      .pipe(map((response) => response.csrf_token));
  }
      */

  getCsrfToken(): Observable<string | null> {
    return of(this.csrfToken);
  }

  refreshCsrfToken(): Observable<string> {
    return this.http
      .get<{ csrf_token: string }>(
        `${environment.apiRoot}/auth/csrf-protection.php`
      )
      .pipe(
        map((response) => response.csrf_token),
        tap((token) => (this.csrfToken = token)),
        catchError((error) => {
          console.error('Failed to refresh CSRF token:', error);
          return of('');
        })
      );
  }

  clearCsrfToken(): void {
    this.csrfToken = null;
  }
}
