import { Component, OnInit } from '@angular/core';
import { AuthService, UserInfo } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Observable, finalize } from 'rxjs'; // finalize を追加
import { Router } from '@angular/router';
import { CsrfTokenService } from 'src/app/shared/services/csrf-token.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  service_name: string = `${environment.service_name}`;
  loggedInUserInfo$: Observable<UserInfo | null> =
    this.authService.loggedInUserInfo$;

  constructor(
    private authService: AuthService,
    private router: Router,
    private csrfTokenService: CsrfTokenService
  ) {}

  ngOnInit(): void {
    // サービス側のログイン状態を取得するメソッドを実行(サービス内の会員情報loggedInUserInfo$も更新される)
    this.authService.checkAuthenticationStatus().subscribe();
  }

  onLogout() {
    this.authService.logout(
      () => {
        console.log('onLogout : Logged out successfully.');
        this.authService
          .checkAuthenticationStatus()
          .pipe(
            finalize(() => {
              // checkAuthenticationStatus の成功・失敗にかかわらず実行される
              this.refreshCsrfTokenAndNavigate();
            })
          )
          .subscribe({
            next: () => console.log('Authentication status checked.'),
            error: (error) =>
              console.error('Error checking authentication status:', error),
          });
      },
      (error) => {
        console.error('Error while logging out:', error);
        alert('Logout failed. Please try again.');
      }
    );
  }

  private refreshCsrfTokenAndNavigate() {
    this.csrfTokenService.refreshCsrfToken().subscribe({
      next: () => {
        console.log('CSRF token refreshed successfully.');
        this.router.navigate(['/']);
      },
      error: (error) => {
        console.error('Error refreshing CSRF token:', error);
        this.router.navigate(['/']);
      },
    });
  }
}
